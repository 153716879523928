
import { defineComponent, ref } from 'vue';

import ChessBoard from './components/chessBoard/ChessBoard.vue';
import SideBar from './components/sideBar/SideBar.vue';

const theme = ref(localStorage.getItem('theme') || 'dark');
function toggleTheme() {
    theme.value = theme.value === 'light' ? 'dark' : 'light';
    localStorage.setItem('theme', theme.value);
}

export default defineComponent({
    name: 'App',
    components: {
        ChessBoard,
        SideBar,
    },
    setup() {
        return {
            theme,
        };
    },
    methods: {
        toggleTheme,
    },
});
