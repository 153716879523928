import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["data-theme"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ChessBoard = _resolveComponent("ChessBoard")!
  const _component_SideBar = _resolveComponent("SideBar")!

  return (_openBlock(), _createElementBlock("div", {
    class: "content",
    "data-theme": _ctx.theme
  }, [
    _createVNode(_component_ChessBoard),
    _createVNode(_component_SideBar, {
      theme: _ctx.theme,
      toggleTheme: _ctx.toggleTheme
    }, null, 8, ["theme", "toggleTheme"])
  ], 8, _hoisted_1))
}